import withAuth from 'components/common/with-auth';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Header from 'components/common/header';
import Title from 'lib/Title';
import theme from 'config/theme';
import Main from 'components/common/main';
import Button from 'lib/Button';
import api from 'api';
import SlidingToggle from 'lib/SlidingToggle';
import { NotificationSettings } from 'models/user';
import { notifySuccess } from 'lib/Notifications';
import ThreeDotsLoader from 'lib/ThreeDotsLoader';
import { ReactComponent as BackIcon } from 'assets/icons/chevron-right.svg';
import routes from 'config/routes';
import { useHistory } from 'react-router-dom';
import useRouter from 'hooks/useRouter';

const { colors } = theme;

const ManageNotifications = () => {
	const [messagingEmail, setMessagingEmail] = useState(false);
	const [messagingSms, setMessagingSms] = useState(false);
	const [listingEmail, setListingEmail] = useState(false);
	const [listingSms, setListingSms] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [goTo] = useRouter();
	const history = useHistory();

	useEffect(() => {
		setIsLoading(true);
		api.userV2
			.getNotificationSettings()
			.then(({ data }) => data)
			.then((response: NotificationSettings) => {
				setMessagingEmail(response.enquiries_and_messages_email);
				setMessagingSms(response.enquiries_and_messages_sms);
				setListingEmail(response.listing_updates_email);
				setListingSms(response.listing_updates_sms);
				setIsLoading(false);
			});
	}, []);

	const handleSave = async () => {
		setIsSaving(true);
		const response = await api.userV2.updateNotificationSettings({
			enquiries_and_messages_email: messagingEmail,
			enquiries_and_messages_sms: messagingSms,
			listing_updates_email: listingEmail,
			listing_updates_sms: listingSms,
		});

		if (response.status < 250) {
			notifySuccess('Settings updated');
			goTo(routes.account_dashboard);
		}

		setIsSaving(false);
	};

	return (
		<>
			<Header />
			<Main>
				<ScreenContainer>
					<ScreenContent>
						<BackAction onClick={() => history.goBack()}>
							<BackIcon style={{ transform: 'rotate(180deg)', marginRight: 12 }} /> Back to account
						</BackAction>
						<Title standardMargin>Notifications</Title>
						<Text>Configure how you receive communications</Text>
						{isLoading ? (
							<LoadingStateContainer>
								<ThreeDotsLoader />
							</LoadingStateContainer>
						) : (
							<>
								<SectionTitle>Enquiries and messages</SectionTitle>
								<Text>Receive booking enquiry updates and messages from tenants or landlords</Text>
								<ToggleRow>
									<Text>Email</Text>
									<SlidingToggle
										value={messagingEmail}
										onToggle={() => setMessagingEmail(prev => !prev)}
									/>
								</ToggleRow>
								<ToggleRow>
									<Text>Sms</Text>
									<SlidingToggle
										value={messagingSms}
										onToggle={() => setMessagingSms(prev => !prev)}
									/>
								</ToggleRow>
								<SectionTitle>Listing updates</SectionTitle>
								<Text>Receive listing moderation, activation and deactivation notifications</Text>
								<ToggleRow>
									<Text>Email</Text>
									<SlidingToggle
										value={listingEmail}
										onToggle={() => setListingEmail(prev => !prev)}
									/>
								</ToggleRow>
								<ToggleRow>
									<Text>Sms</Text>
									<SlidingToggle value={listingSms} onToggle={() => setListingSms(prev => !prev)} />
								</ToggleRow>
								<ActionBar>
									<Button isLoading={isSaving} onClick={handleSave}>
										Save
									</Button>
								</ActionBar>
							</>
						)}
					</ScreenContent>
				</ScreenContainer>
			</Main>
		</>
	);
};

const ScreenContainer = styled.div`
	border-top: 2px solid ${colors.grey10};
	width: 100%;
	padding: 24px;
`;

const ScreenContent = styled.div`
	margin: auto;
	margin-top: 24px;
	max-width: 800px;
`;

const Text = styled.p`
	font-size: 14px;
	color: ${colors.grey60};
	margin: 0;
`;

const SectionTitle = styled.p`
	font-size: 16;
	font-weight: 600;
	margin-bottom: 4px;
	margin-top: 24px;
`;

const ActionBar = styled.div`
	margin-top: 24px;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const ToggleRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${colors.grey10};
	padding: 16px 0;
`;

const LoadingStateContainer = styled.div`
	display: flex;
	margin-top: 24px;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const BackAction = styled.div`
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 24px;
	cursor: pointer;
	color: ${colors.darkBlue};
	font-size: 14px;
`;

export default withAuth(ManageNotifications);
