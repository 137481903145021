import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'config/theme';

const { colors } = theme;

interface SlidingToggleProps {
	value: boolean;
	onToggle(): void;
}

const SlidingToggle = ({ value, onToggle }: SlidingToggleProps) => {
	return (
		<Label>
			<CheckboxInput type="checkbox" checked={value} onChange={onToggle} />
			<Slider></Slider>
		</Label>
	);
};

export default SlidingToggle;

const Label = styled.label`
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	transform: scale(0.9);
`;

const Slider = styled.span`
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: ${colors.grey20};
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 34px;

	&::before {
		position: absolute;
		content: '';
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: ${colors.white};
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}
`;

const CheckboxInput = styled.input`
	display: none;

	&:checked + ${Slider} {
		background-color: ${colors.darkTurquoise};
	}

	&:checked + ${Slider}::before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}
`;
